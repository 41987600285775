<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework notification preferences</h3>


    <p>All signed in users can set <strong>Framework Notification Preferences</strong> from the HAMBURGER MENU in the top right corner of their screen.
        <br>
        <br>
        <img alt="Framework notification preferences image" srcset="/docimages/email_notification.png 1.1x" class="k-help-img float-right">
        <ol class="k-no-clear">
            <li>Select <strong>Send email notifications when frameworks change</strong> to set up your preferences.</li>
            <li>You may elect to be notified when changes are made to anything in your <span v-html="app_name"/>  instance, selected categories of frameworks, or specific frameworks (subjects).</li>
            <li>Verify the email address</li>
            <li>Choose SAVE CHANGES</li>
        </ol>
    </p>
    <br>
    <br>
    <p>Signed in users can also view changes within <span v-html="app_name"/> , from the <span v-html="link('framework_update', 'Framework Update Report')"></span>.</p>

	
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>